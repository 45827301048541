header {
  padding: 20px;
}

section.hero {
  padding: 60px;
  padding-bottom: 100px;
  text-align: center;
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 600px) {
  section.hero {
    padding: 25px;
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
    position: relative;
    z-index: 2;
  }
}

.hero-container {
  background: linear-gradient(to bottom, #FF3833, #FF914E);
}

section.hero h1 {
  color: white;
  font-size: 120px;
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 50px;
  max-width: 1000px;
}

@media only screen and (max-width: 600px) {
  section.hero h1 {
    color: white;
    font-size: 60px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 50px;
    max-width: 1000px;
  }
}

section.hero input {
  padding: 10px 20px;
  border-radius: 50px;
  border: 3px solid #e2e2e2;
  font-size: 24px;
  margin-right: 10px;
  box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.2); /* Adjust the values as needed */
  color: #2A2B2E;
  background-color: white;
  font-weight: 500;
  z-index: 9999;
}

@media only screen and (max-width: 600px) {
  section.hero input {
    padding: 10px 20px;
    border-radius: 50px;
    border: 3px solid #e2e2e2;
    font-size: 24px;
    margin-right: 0px;
    box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.2); /* Adjust the values as needed */
    color: #2A2B2E;
    background-color: white;
    font-weight: 500;
    z-index: 9999;
    box-sizing: border-box;
    margin-bottom: 10px;
    width: 100%;
  }
}

section.hero input::placeholder {
  color: #959595;
}

section.hero input:focus {
  outline: none;
}

section.hero button {
  padding: 10px 20px;
  background-color: #2A2B2E;
  border-radius: 50px;
  border: 3px solid #2A2B2E;
  font-size: 24px;
  margin-right: 10px;
  box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.2); /* Adjust the values as needed */
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
  z-index: 9999;
}

@media only screen and (max-width: 600px) {
  section.hero button {
    width: 100%;
  }
}

section.hero .emojis {
  position: absolute;
  font-size: 120px;
  top: 50%; /* Adjust top position */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%);
  width: 90%; /* Make the emojis span the entire width */
  max-width: 1200px;
  z-index: 1;
}

@media only screen and (max-width: 600px) {
  section.hero .emojis {
    display: none;
  }
}

section.hero .emoji-row {
  display: flex;
  justify-content: space-between; /* Spread emojis across the width */
}

section.hero .emoji-row.top {
  margin-bottom: 40px; /* Adjust the space between the top and bottom rows */
}

section.hero .emoji-row.bottom {
  margin-top: 20px; /* Adjust the space between the bottom and top rows */
}

.emoji-right-right {
  margin-right: 100px;
  rotate: 10deg;
}

.emoji-right-r {
  rotate: 10deg;
}

.emoji-left-left {
  margin-left: 100px;
  rotate: -10deg;
}

.emoji-left-l {
  rotate: -10deg;
}

section.video {
  text-align: center;
  padding: 40px;
  background-color: #ffffff;
}

@media only screen and (max-width: 600px) {
  section.video {
    text-align: center;
    padding: 25px;
    background-color: #ffffff;
  }
}

.vid {
  text-align: center;
  width: 300px;
  border-radius: 30px;
  margin-top: 50px;
}

footer {
  text-align: center;
  margin-top: 50px;
}

.nav {
  margin-bottom: -160px;
  align-items: center;
  display: flex;
  justify-content: space-between;

  padding: 40px;
}

#logo {
  width: 70px;
  height: auto;
}

@media only screen and (max-width: 600px) {
  #logo {
    width: 50px;
    height: auto;
  }
}

footer {
  padding: 40px;
  background-color: #2A2B2E;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer p {
  margin-right: 20px;
  justify-content: center; /* Center horizontally */
}

#copy {
  margin-right: 40px;
}

#instagram {
  cursor: pointer;
  color: white;
}

#instagram:hover {
  cursor: pointer;
  color: rgb(243, 240, 240);
}

#insta {
  margin-right: 5px;
  margin-bottom: -2px;
}

a {
  text-decoration: none;
}

#word {
  color:#2A2B2E;
}

#l {
  margin-bottom: 350px;
  margin-right: 50px;
}

@media only screen and (max-width: 600px) {
  #l {
    display: none;
  }
  }

#r {
  margin-bottom: 350px;
  margin-left: 50px;
}

@media only screen and (max-width: 600px) {
  #r {
    display: none;
  }
  }


@media only screen and (max-width: 600px) {
.arrows {
    display: none;
  }
}


.left-arrows {
  margin-left: 00px; /* Adjust the margin to bring arrows closer together */
}

.right-arrows {
  width: 0px;
}

.arrows img {
  width: 200px; /* Adjust the width as needed */
  height: auto;
}

#download {
  width: 150px;
  height: auto
}

.image-area {
  text-align: right;
}

#date {
  color: white;
  font-weight: 600;
  font-size: 20px; 
  margin-top: 5px;

}